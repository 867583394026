import { Component, inject, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA } from '../modal/dialog-tokens';
import { DialogRef } from '../modal/dialog-ref';
import { CoreLibModalComponent } from '../modal/modal.component';
import { UtilService } from 'app/shared/util.service';
import { CasesService, ShareLinks } from 'app/shared/cases/services/cases.service';

@Component({
  selector: 'mh-core-lib-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss'],
})
export class CoreLibShareModalComponent extends CoreLibModalComponent implements OnInit {
  readonly utilService = inject(UtilService);
  shareLinks: ShareLinks;
  loading = false;

  constructor(protected dialogRef: DialogRef, @Inject(DIALOG_DATA) public data, private casesService: CasesService) {
    super(dialogRef, data);
  }

  ngOnInit(): void {
    this.getShareLinks();
  }

  getShareLinks() {
    this.loading = true;
    const languageId = this.utilService.getCurrentLanguageId();
    this.casesService.getShareLinks(this.data.case.id, languageId).subscribe((result) => {
      this.shareLinks = result;
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  handleShare(source: string) {
    if (source === 'email') {
      this.utilService.ga('', 'desk-click-share-email');
    } else {
      this.utilService.ga('', 'desk-click-share-whatsapp');
    }

    const text = `${source === 'email' ? this.shareLinks?.text_email : this.shareLinks?.text_whatsapp}`;

    const encodedText = encodeURIComponent(text);
    if (source === 'ws') {
      const url = `https://wa.me/?text=${encodedText}`;
      window.open(url, '_blank');
    } else {
      window.location.href = `mailto:?body=${encodedText}`;
    }
  }

  handleCopyUrl(url: string) {
    this.utilService.ga('', 'desk-click-share-copylink');
    navigator.clipboard.writeText(url);
  }
}
