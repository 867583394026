import { FilterDate } from './model/filters/filter-date.model';
import { HotelDto } from './model/hotel_dto.model';
import { Injectable } from '@angular/core';
import {
  Hotel,
  User,
  Language,
  UserProfile,
  InputTranslate,
} from './model/index';
import { TranslateService } from '@ngx-translate/core';

import { Observable ,  Subject } from 'rxjs';
import { Money } from "./model/index";
import { ProductsService,AlertService, SessionService } from '../_services/index';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../_services/google-analytics.service';
import { environment } from "../../environments/environment";
import { DeviceDetectorService } from 'ngx-device-detector';
import { NotificationsService } from "../notifications/notifications.service";
import { Md5 } from 'ts-md5/dist/md5';
import { DemographyService } from "../online/demography/demography.service"
import { Customer } from './customer.model';
import { StoreDefaultLanguage } from 'app/states/default-language/default-language.actions';
import { Store } from '@ngxs/store';
import { StoreCurrentCustomer } from 'app/states/customers/customers.actions';
import { CustomerService } from './customer.service';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  private _idCustomer:string;
  private _nameCustomer:string;
  private _startDate:string;
  private _endDate:string;
  private _prevStartDate:string;
  private _prevEndDate:string;
  public filterDate;
  private _currentHotel: Hotel;
  public  currentHotelDto:HotelDto;
  private _currentLanguage: string;
  private _currentCurrency:Money;
  public  moneys:Money[] = []
  private _currentSection: string = "/followup";
  private _afterCurrentSection = '';
  private monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
  ];
  private _screenHeight: number=100;
  private _screenWidth: number=100;
  private _interChainHotels:Hotel[];
  private _propertyTypes = {
    "1": "Hotel",
    "2": "Hostel",
    "3": "Restaurant",
    "4": "Private",
    "5": "Retail",
    "6": "Clinic",
    "7": "Airline"
  }
  public  productChain:Hotel[];
  private _demoInterChainHotels:Hotel[];
  public currentUser:User;
  public currentFakeUser:User;
  public currentUrl:string="/";
  public allHotels:Hotel[]=[];

  public changingCurrentSurvey = true;

  dateSegmentedBy = 'months';
  hostel_otas = [33];
  currentUsers:User[] = [];
  free_period_end=false;
  currentProductId: any = 1;

  countries = [];
  cities: any = [];

  languages = [
    {code: 'es', id:1},
    {code: 'en', id:2},
    {code: 'pt', id:7}
  ]

  fidelityProducts = {
    1: 'followup',
    2: 'onsite',
    3: 'online',
    4: 'prestay',
    5: 'collect',
    6: 'cases',
  }

  translateLanguages:string[] = this.languages.map(x=>x.code);

  public _monthNames = {
    "en" : ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    "es" : ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    "pt" : ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]
  };

  public qualifys:object = {
    "very_good": {
      "name" : "Sobresaliente"
    },
    "good":{
      "name": "Muy Bueno"
    },
    "regular":{
      "name": "Regular"
    },
    "bad":{
      "name": "Malo"
    },
    "very_bad":{
      "name": "Terrible"
    }
  }

  activeOtas = [];
  availableLanguages: any[] = [];


  private subject                  = new Subject<any>();
  private customerSubject          = new Subject<any>();
  private competitorsSubject       = new Subject<any>();
  private customersReady           = new Subject<any>();
  private innerChainReady          = new Subject<any>();
  private demoSetted               = new Subject<any>();
  private dateReady                = new Subject<any>();
  //ESTO FUE AGREGADO SOLO POR FACILIDAD EN LA TRANSISION A SACAR LAS FECHAS DE
  //ESTA CLASE
  private dateReadyRaw             = new Subject<any>();
  private reloadUsers              = new Subject<UserProfile>();
  private subjectToggleNavbar      = new Subject<any>();
  private subjectScrollMainContent = new Subject<number>();
  private subjectLanguage          = new Subject<number>();
  private subjectNavbar            = new Subject<number>();
  private subjectFadeGeneral       = new Subject<any>();
  private subjectCurrentModal      = new Subject<any>();
  private subjectHeader            = new Subject<any>();
  private subjectAlerts            = new Subject<any>();
  private subjectResize            = new Subject<any>();
  private subjectAddNewHotel       = new Subject<any>();
  private subjectSurvey            = new Subject<any>();
  private subjectIsPaid            = new Subject<any>();
  private subjectFilterClicked     = new Subject<boolean>();

  private is_paid: boolean = true;


  constructor(
    private productsService:ProductsService,
    private router: Router,
    private analytics:GoogleAnalyticsService,
    private translate: TranslateService,
    private deviceService: DeviceDetectorService,
    private notificationsService:NotificationsService,
    private alertService: AlertService,
    private sessionService: SessionService,
    public demographyService: DemographyService,
    private store: Store,
    private customerService: CustomerService,
  ){
    this.availableLanguages = [
      new Language(1, 'es'),
      new Language(2, 'en'),
      new Language(3, 'zh'),
      new Language(4, 'hi'),
      new Language(5, 'ar'),
      new Language(6, 'bn'),
      new Language(7, 'pt'),
      new Language(8, 'ru'),
      new Language(9, 'ja'),
      new Language(10, 'de'),
      new Language(11, 'fr'),
      new Language(12, 'it'),
      new Language(14, 'other')
    ];
    if(this.currentUser===undefined) this.getCurrentUser();
    this.free_period_end = false;
    this._currentLanguage = this.sessionService.getCurrentLanguage();
  }

  ga(eventCategory: string,eventAction: string, eventLabel: string | null = null, eventValue: number | string | null = null){
    if(!this.isProduction() || !this.currentUser || !this.currentUser.isSuperAdmin()) {
      this.analytics.emitEvent(eventCategory, eventAction,eventLabel,eventValue)
    }
  }

  setScoreNps(formula){
    localStorage.setItem('scorePromoter',formula.promoter);
    localStorage.setItem('scoreDetractor',formula.detractor);
  }

  getScorePromoterNps() : any{
    return localStorage.getItem('scorePromoter')
  }

  getScoreDetractorNps(): any{
    return localStorage.getItem('scoreDetractor')
  }

  setCurrentCustomerId(id){
    localStorage.setItem('currentCustomerId',id)
  }
  getCurrentCustomerId(){
    return localStorage.getItem('currentCustomerId')
  }

  setAfterCustomerId(id){
    localStorage.setItem('currentAfterId',id)
  }
  getAferCustomerId(){
    return localStorage.getItem('currentAfterId')
  }

  getCurrentLanguage(){
    return this.sessionService.getCurrentLanguage();
  }

  getCurrentLanguageId(){
    const language = this.getCurrentLanguage();
    return this.getLanguageId(language);
  }

  getLanguageId(code: any=null){
    return this.sessionService.getLanguageId(code);
  }

  setCurrrentLanguage(language){
    this.currentLanguage = this.sessionService.setCurrrentLanguage(language);
    const languageSelected = this.languages.find((lang) => lang.code === language);
    this.store.dispatch(new StoreDefaultLanguage(languageSelected.code, languageSelected.id));
    this.subjectLanguage.next(language);
  }


  getCurrentSurvey(customersFilter:number[] = []){
    const surveys = this.sessionService.getCurrentSurveys();
    if (!surveys) return null;

    if(customersFilter.length === 0){
      return surveys;
    }else{
      const filter = surveys.survey.filter(x=>customersFilter.includes(x.customer_id));
      const customers = this.uniqArray(filter.map(x=>x.customer_id));
      return {
        ids: filter.map(x=>x.id).join(','),
        survey: filter,
        chain: customers.join(','),
        customers: customers
      }
    }
  }

  setCurrentSurvey(ids:string,survey:any[]){
    this.sessionService.setCurrentSurvey(ids,survey);
    this.changingCurrentSurvey = false;
    this.subjectSurvey.next(ids);
  }

  isCurrentSurveyChanged(): Observable<any>{
    return this.subjectSurvey.asObservable();
  }

  getCurrentUser():User{
    this.currentUser = this.sessionService.getCurrentUser();
    return this.currentUser;
  }

  refreshCurrentUser(){
    this.sessionService.refreshCurrentUser();
  }

  getCurrentFakeUser():User{
    this.currentFakeUser = this.sessionService.getCurrentFakeUser();
    return this.currentFakeUser;
  }

  setCurrenFakeUser(user:User){
    this.currentUser.fakeUser = this.sessionService.setCurrenFakeUser(user);
  }

  getCurrentHotel():Hotel{
    this.currentHotel = this.sessionService.getCurrentCustomer();
    return this.currentHotel;
  }

  getCurrentCustomer():Customer{
    return this.getCurrentHotel()
  }

  getCurrentSurveyId():number{
    return this.sessionService.currentSurveyId;
  }

  setCurrentSurveyId(id){
    this.sessionService.currentSurveyId = id;
  }


  getCurrentHotelRAW():Hotel{
    return this.sessionService.getCurrentCustomerRAW();
  }

  getFidelityConfigurations(){
    return this.sessionService.getFidelityProperties();
  }

  getFidelityConfigurationsByProduct(product, section){
    const fidelityConfigurations = this.getFidelityConfigurations();
    return fidelityConfigurations.products[product].menu[section].widgets;
  }

  hideCurrentName(value:string){
    let currentHotel = this.getCurrentHotelRAW();
    return this.isLiveDemo() ? value.replace(currentHotel.name.toLocaleLowerCase(), this.currentHotel.name) : value;
  }

  sendMessige(message: string) {
      this.subject.next({ text: message });
  }

  clearMessage() {
      this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }


  hasAlerts(): Observable<any> {
    return this.subjectAlerts.asObservable();
  }

  sendAlert(message='errors.default',type='error', title='' ,style:'stick' | 'modal' ='stick', translateParams={}, smallMessage=''){
    if(style==='stick'){
      this.alertService[type](message);
    }else{
      this.subjectAlerts.next({
        message : message,
        title : title,
        type: type,
        style: style,
        smallMessage: smallMessage,
        translateParams: translateParams
      })
    }


  }

  validateEmail(email: string) {
    const regEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
    return regEmail.test(email.toLowerCase())
  }

  async customerChanged(hotel: Hotel,emit=true){
    this.clearChache();
    this.currentHotel = this.sessionService.setCurrentCustomer(hotel);
    if (emit) {
      const currentUser = this.getCurrentUser();
  
      const isChain = await this.customerService.getIsChain(this.currentHotel, currentUser);
      await this.productsService.loadProducts(
        this.currentUser,
        this.currentHotel,
        this.getFidelityConfigurations(),
        isChain,
      );
      this.checkAccessView();
      this.customerSubject.next(hotel);
      this.store.dispatch(new StoreCurrentCustomer(hotel));
      this.refreshNavbar();
    }
  }

  clearQueryParams(activeRoute, param) {
    this.router.navigate(
      ['.'],
      { relativeTo: activeRoute, queryParams: param }
    );
  }

  competitorsChanged(hotel: any){
    this.competitorsSubject.next(hotel)
  }

  refreshCurrentHotel(){
    this.sessionService.refreshCurrentCustomer();
  }

  isCustomerChanged(): Observable<any> {
    return this.customerSubject.asObservable();
  }

  isCompetitorsChanged(): Observable<any> {
    return this.competitorsSubject.asObservable();
  }

  isLanguageChanged(): Observable<any> {
    return this.subjectLanguage.asObservable();
  }
  isNavbarChanged(): Observable<any> {
    return this.subjectNavbar.asObservable();
  }

  isCurrenModalChange(): Observable<any> {
    return this.subjectCurrentModal.asObservable();
  }

  isUsersReload(): Observable<UserProfile> {
    return this.reloadUsers.asObservable();
  }

  isToggleNavbar(): Observable<boolean> {
    return this.subjectToggleNavbar.asObservable();
  }

  isHeaderRefeshed(): Observable<User> {
    return this.subjectHeader.asObservable();
  }

  isForceResize(): Observable<User> {
    return this.subjectResize.asObservable();
  }

  isAddANewHotel(): Observable<User> {
    return this.subjectAddNewHotel.asObservable();
  }

  toggleNavbar(value = false){
    this.subjectToggleNavbar.next(value);
  }

  refreshNavbar(){
    this.subjectNavbar.next()
  }

  refreshHeader(){
    this.subjectHeader.next()
  }

  forceResize(){
    this.subjectResize.next();
  }

  newHotelAdded(){
    this.subjectAddNewHotel.next();
  }

  isScrollMainContent(): Observable<number> {
    return this.subjectScrollMainContent.asObservable();
  }

  topScrollMainContent(value:number=0){
    this.subjectScrollMainContent.next(value);
  }

  isFadeActions(){
    return this.subjectFadeGeneral.asObservable();
  }

  setGeneralFade(state){
    setTimeout(_ => {
      this.subjectFadeGeneral.next(false);
    },500);
    this.subjectFadeGeneral.next(state);
  }

  setCurrentModal(state,name){
    let info={
      state: state,
      name: name
    }
    this.subjectCurrentModal.next(info);
  }

  refreshUsers(newUser:UserProfile){
    this.reloadUsers.next(newUser);
  }

  loadCustomerReady(){
    this.customersReady.next()
  }

  updateCurrentUser(){
    localStorage.setItem('currentUser',  JSON.stringify(this.currentUser));
  }

  isLoadCustomerReady(){
    return this.customersReady.asObservable();
  }

  clearChache(){
    localStorage.setItem('cacheObjects', JSON.stringify({}));
  }

  checkAccessView(){
    const pathName = this.currentSection !== window.location.pathname ? window.location.pathname : this.currentSection;
    if(!this.productsService.hasUrlAccess(pathName) && !this.productsService.hasNotProduct(pathName)){
      this.router.navigate(['/'])
    };
  }

  getObjetFromArrayByKey(array, value, key): any{
    let result = array.filter(function( obj ) {
      return obj[key] === value;
    });
    return result;
  }

  qualifyScore(score:number, getName:boolean=true):string{
    let qualify:string;
    if(score >= 4.75){
      qualify = "very_good"
    }else if(score>=4.35 && score<4.75){
      qualify = "good"
    }else if(score>=3.65 && score<4.35){
      qualify = "regular"
    }else if(score>=2.95 && score <3.65){
      qualify = "bad"
    }else{
      qualify = "very_bad"
    }
    return getName ? this.qualifys[qualify]["name"] : qualify;
  }

  qualifyType(score:number): number{
    let score_string = this.qualifyScore(score, false)
    let keys = Object.keys(this.qualifys);
    return keys.indexOf(score_string)+1;
  }

  qualifyPercent(percent){
    let qualify;
    if(percent < 15){
      qualify = this.qualifyScore(2,false);
    }
    if(percent >=15 && percent < 28){
      qualify = this.qualifyScore(3,false);
    }
    if(percent >=28 && percent < 38){
      qualify = this.qualifyScore(4,false);
    }
    if(percent >=38 && percent < 60){
      qualify = this.qualifyScore(4.4,false);
    }
    if(percent >=60){
      qualify = this.qualifyScore(4.8,false);
    }
    return qualify;
  }

  qualifyNps(type){
    if(type===1 || type==="very_good") return "promoter";
    if(type===2 || type==="good") return "passive";
    if([3,4,5,"regular","bad","very_bad"].includes(type)) return "detractor";
  }

  qualifyOnlineNps(score){
    if(score>=this.getScorePromoterNps()) return "promoter";
    if(score<this.getScorePromoterNps() && score >=this.getScoreDetractorNps()) return "passive";
    if(score<this.getScoreDetractorNps()) return "detractor";
  }

  getIdNPS(type){
    if(type==="promoter") return 0;
    if(type==="passive") return 1;
    if(type==="detractor") return 2;
    return -1;
  }

  getIntervalLabels(startDate:Date,endDate:Date,type="months"){
    let periods: any[] = []
    let language     = this.getCurrentLanguage();
    let distanceDate = Math.floor(this.getDistanceDates(startDate,endDate,type));
    let currentDate: any = startDate;

    for (let i = 0; i <=distanceDate; ++i) {
      currentDate = i===0 ? startDate : this.getNextDate(currentDate,1,type)
      if(type==="months") periods.push(this.labelsToMonths(currentDate,language));
      if(type==="weeks")  periods.push(this.labelsToWeeks(currentDate,language));
      if(type==="days")  periods.push(this.labelsToDays(currentDate,language));
    }

    if(type==="weeks") {
      periods.pop(); // Dado que no se tiene el valor de la última semana aún
    }

    return periods;
  }

  labelsToMonths(currentDate:Date,language){
    currentDate.setDate(1);
    let month     = currentDate.getMonth() + 1;
    let monthParser = month < 10 ? '0'+month : month;
    let year      = currentDate.getFullYear().toString()
    let format    = this.formatDateLabel(currentDate,language);
    let label     = year+"-"+monthParser;
    return new DataPeriod(label, 0,currentDate,format);
  }

  labelsToWeeks(currentDate:Date,language){
    let labels: any[] = [];
    currentDate = this.getMonday(currentDate);
    let date = new Date(currentDate.toDateString());

    for(let period of ['start','end']){
      date = period === 'start' ? date : new Date(date.setDate(date.getDate()+6))
      let month     = this.formatSingleNumber(date.getMonth() + 1);
      let year      = date.getFullYear().toString()
      let day       = this.formatSingleNumber(date.getDate());
      labels.push(year+"-"+month+"-"+day);
    }

    const label = labels.join('|');
    const format    = this.formatDateLabel(currentDate,language,"weeks");

    return new DataPeriod(label, 0,currentDate,format);
  }

  labelsToDays(currentDate:Date,language){
    let month     = this.formatSingleNumber(currentDate.getMonth() + 1);
    let year      = currentDate.getFullYear().toString()
    let day       = this.formatSingleNumber(currentDate.getDate());
    const label   = year+"-"+month+"-"+day
    const format    = this.formatDateLabel(currentDate,language,"days");
    return new DataPeriod(label+'|'+label, 0,currentDate,format);
  }


  formatDateLabel(date:Date, language:string,type="months"):string{
    let month     = type==="months" ? this._monthNames[language][date.getMonth()] : this.formatSingleNumber(date.getMonth()+1);
    let year      = date.getFullYear().toString().substr(2,2);
    let day       = this.formatSingleNumber(date.getDate());
    if(type==="months") return month+"-"+year;
    return day+"-"+month+"-"+year;
  }

  getMonday(date:Date) {
    const day   = date.getDay();
    const diff  = date.getDate() - day + (day === 0 ? -6:1); // adjust when day is sunday
    return new Date(date.setDate(diff));
  }

  isMobile(){ return this.deviceService.isMobile()};
  isTablet(){ return this.deviceService.isTablet()};
  iDesktop(){ return this.deviceService.isDesktop()};

  public formatDate(date:Date, yearFirst = true, addMonth = true){

    let mm = addMonth ? date.getMonth() + 1 : date.getMonth();
    let dd = date.getDate();

    if (yearFirst) {
      return [date.getFullYear(),
        (mm>9 ? '' : '0') + mm,
        (dd>9 ? '' : '0') + dd
      ].join('-');
    }
    return [
      (dd>9 ? '' : '0') + dd,
      (mm>9 ? '' : '0') + mm,
      date.getFullYear()
    ].join('-');
  }

  formatTime(date:Date|string, showSeconds = false){
    if(typeof date ==='string') date = new Date(date);
    let localeSpecificTime = date.toLocaleTimeString();
    localeSpecificTime = localeSpecificTime.replace(/:\d+ /, ' ');
    const time = localeSpecificTime.split(':');
    return showSeconds ? time.join(':') : time[0]+':'+time[1];
  }



  public stringDateToDate(dateString:string): Date{ //format yyyy-mm-dd
    let start_date:string  = dateString;
    if(start_date === undefined){
      return new Date(0,0,0);
    }

    let date  = start_date.split('-');
    let month = parseInt(date[1]) - 1;
    let year  = parseInt(date[0]);
    let day   = parseInt(date[2]);
    return new Date(year,month,day);
  }

  getDateFromPeriod(period:string){ //format yyyy-mm-dd or yyyy-mm
    let split = period.split('-');
    let format_perdiod = split.length> 2 ? period : period+'-01';
    let date = this.stringDateToDate(format_perdiod);
    return date;
  }

  formatPercent(value, round=true, scale=false,show_zero=false){
    let result = value;
    if(scale)  result = Math.round(value*100)/100;
    if(Math.abs(value)<1) value = Math.round(value*100)/100;
    result = round && Math.abs(value) > 1 ? Math.round(value) : value;
    if(isNaN(result) || result >1000 ) result = 0; //nan or infinity
    return (result === 0 && !show_zero) ? '-' : result+'%';
  }

  getAbsoluteNumber(value: number) {
    return Math.abs(value)
  }

  formatNumber(value, format='none',fix=2){
    let fixed = Math.pow(10,fix);
    let round = Math.round(value*fixed)/fixed;
    if(format==="score"){
      return this.formatDecimals(value,2,fix);
    }
    if(format==='average'){
      return round;
    }
    return isNaN(value) ? 0 : value;
  }

  formatDecimals(value, decimals=2, fix= 2){
    const elevate = Math.pow(10,decimals);
    const result  = Math.round(value*elevate)/elevate;
    return result;
  }

  findInputTranslate(translates: InputTranslate[],language='es',key='label'){
    if(!translates || !translates.length) return '';
    let languageId = this.getLanguageId(language);
    let translate  = translates.find(x=>x.language_id===languageId) || translates.find(x=>x['language'].id===languageId);
    return translate ? translate[key] : translates[0][key];
  }

  getNameTranslate(languageId,arrayTranslates){
    let name;
    arrayTranslates.forEach(element => {
      if(element.language_id === languageId)
      name = element.name;
    });
    return(name);
  }

  formatSingleNumber(number:number){
    return number < 10 ? '0'+number : number;
  }

  public getLastMonth(back=1, firstDay=true, date = new Date()): Date{ //return the 1st day of last month
    if(firstDay){
      date.setDate(1);
    }
    date.setMonth(date.getMonth() - back);
    return date;
  }

  public getLastWeeks(back=1,date = new Date()): Date{ //return the 1st day of last month
    date.setDate(date.getDate() -(7*back) - date.getDay() - 6);
    return date;
  }

  public getLastDays(back=1,date = new Date()): Date{
    date.setDate(date.getDate() - back);
    return date;
  }

  public getLastDayOnMonth(month:Date): Date{
    let lastDayOfMonth = new Date(month.getFullYear(), month.getMonth()+1, 0);
    return lastDayOfMonth;
  }


  public getNextDate(date:Date,step=1, type="months"){
    if(type==="months") return this.getNextMonth(date,step);
    if(type==="weeks") return this.getNextWeek(date,step);
    if(type==="days") return this.getNextDay(date,step);
  }

  public getNextMonth(date:Date,step=1){
    if(step===0) return date;
    if(date.getMonth() === 11) {
      return new Date(date.getFullYear() + 1, 0, 1);
    } else {
      return new Date(date.getFullYear(), date.getMonth() + 1, 1);
    }
  }

  public getNextWeek(date:Date,step=1){
    if(step===0) return date;
    date.setDate(date.getDate()+7*step);
    return date;
  }

  public getNextDay(date:Date,step=1){
    if(step===0) return date;
    date.setDate(date.getDate()+step);
    return date;
  }

  public getYesterday(back:number=1, startDate:Date | any=undefined): Date{
    let date = startDate ? startDate : new Date();
    date.setDate(date.getDate() - back);
    return date;
  }

  getFirstDateAndFirstMonth(date):string{
    let dateInit  = typeof(date) === "string" ? this.stringDateToDate(date) : date;
    return dateInit.getFullYear()+"-01-01";
  }

  getAppVersion(){
    let appVersion  = require('../../../package.json').version;
    return appVersion;
  }

  public getDistanceDates(initDate,endDate, type="days"):number{
    if( !initDate || !endDate ) return 0; 
    let dateInit  = typeof(initDate) === "string" ? this.stringDateToDate(initDate) : initDate;
    let dateEnd   = typeof(endDate) === "string" ? this.stringDateToDate(endDate) : endDate;

    const _MS_PER_WEEK = 1000 * 60 * 60 * 24 * 7;
    const _MS_PER_DAY  = 1000 * 60 * 60 * 24;
    const _MS_PER_HOUR = 1000 * 60 * 60;
    const _MS_PER_MINUTE = 1000 * 60;
    let utc1          = Date.UTC(dateInit.getFullYear(), dateInit.getMonth(), dateInit.getDate(), dateInit.getHours(), dateInit.getMinutes(), dateInit.getSeconds());
    let utc2          = Date.UTC(dateEnd.getFullYear(), dateEnd.getMonth(), dateEnd.getDate(),dateEnd.getHours(), dateEnd.getMinutes(), dateEnd.getSeconds());
    let diffDays      = Math.floor((utc2 - utc1) / _MS_PER_DAY);

    let diffWeeks     = Math.floor((utc2 - utc1) / _MS_PER_WEEK);

    let diffMonth     = diffDays/30;
    let difHours      = Math.floor((utc2 - utc1) / _MS_PER_HOUR);
    let difMinutes    = Math.floor((utc2 - utc1) / _MS_PER_MINUTE);


    if(type==="hours") return difHours;
    if(type==="minutes") return difMinutes;
    if(type==="days") return diffDays;
    if(type==="weeks") return diffWeeks;
    return diffMonth;
  }


  public getShortDate(value:string):string{
    let inDate = new Date(value);
    let shortDate=this.monthNames[inDate.getMonth()];
    shortDate=shortDate.substr(0,3)+"'"+String(inDate.getFullYear()).substr(2,4);

    return shortDate;
  }

  public getCustomShortDate(value:string):string{
    let inDate = new Date(value);
    let shortDate=this.monthNames[inDate.getMonth()+1];
    shortDate=shortDate.substr(0,3)+"'"+String(inDate.getFullYear()).substr(2,4);

    return shortDate;
  }

  public getHotel(hotelId): Hotel | any{
    return this.interChainHotels && this.interChainHotels.find(x => x.id === hotelId);
  }

  public getInnerChain(join=true,filter='none'){
    let user      = this.currentUser?.isSuperAdmin() && this.currentUser?.fakeUser ? this.currentUser?.fakeUser : this.currentUser;
    let customers =  user?.customers || [];
    if(this.isLiveDemo() && this.currentUser?.fakeUser){
      customers = this.setDemoInterChain(user);
    }

    if(filter!=='none'){
      customers = customers.filter(x=>x[filter]);
      this.productChain = customers;
    }
    customers = customers.filter(x => x.id !== '12'); //ignoring demo myhotel
    customers = this.sortArray(customers,'id');
    return customers || [];
  }

  public getFullUrl(){
    return (this.router.url)
  }

  getProductNameFromUrl() {
    const urlSplit = this.router.url.split('/')
    switch(urlSplit[1]) {
      case 'prestay':
        return 'PreStay'
      case 'followup':
        return 'FollowUp'
      case 'online': {
        switch(urlSplit[2]) {
          case 'otas':
            return 'Online-Otas'
          default:
            return 'Online'
        }
      }
      case 'onsite':
        return 'OnSite'
      case 'intercadena': {
        switch(urlSplit[2]) {
          case 'onsite':
            return 'Corp-OnSite'
          case 'online':{
            switch(urlSplit[3]) {
              case 'propiedades':
                return 'Corp-Online-Propiedades'
              case 'tripadvisor':
                return 'Corp-Online-TripAdvisor'
              case 'cobertura':
                switch(urlSplit[4]) {
                  case 'hotel':
                    return 'Corp-Online-Cobertura-Hotel'
                  case 'calificaciones':
                    return 'Corp-Online-Cobertura-Calificaciones'
                }
            }
          }
        }
      }
    }
  }

  getChain(filter='none'):any[]{
    return this.getInnerChain(false,filter);
  }

  innerChainItsReady(){
    this.innerChainReady.next()
  }

  isInnerChainIsReady(){
    return this.innerChainReady.asObservable();
  }

  public formatNPS(nps_val){
    let nps = parseInt(nps_val);
    return nps > 0 ? "+"+nps : ""+nps;
  }

  shuffle(array):any[] {
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }


  setCurrentSection(section){
    let url = this.clearUrl(section);
    if(url !== this.currentSection){
      this.currentSection = url;
      // this.checkWarings('change_route');
      this.refreshNavbar()
    }

  }

  clearUrl(link){
    return link.indexOf("?") > 0 ? link.substring(0,link.indexOf("?")) : link;
  }

  sortArray(array: any[] = [],byKey,order="ASC"){
    if(order==="DESC"){
      array.sort(function(a,b) {return (a[byKey] < b[byKey]) ? 1 : ((b[byKey] < a[byKey]) ? -1 : 0);} )
    }else{
      array.sort(function(a,b) {return (a[byKey] > b[byKey]) ? 1 : ((b[byKey] > a[byKey]) ? -1 : 0);} );
    }
    return array;
  }

  concatArray(array=[]){
    return [].concat.apply([], array);
  }

  uniqArray(array: any[] = []){
    return array.filter((v, i, a) => a.indexOf(v) === i )
  }

  setDemoInterChain(user:User):Hotel[]{
    let hotels:Hotel[]=[];
    this.demoInterChainHotels = []
    for (let i = 0; i < user.customers.length; ++i) {
      let params = user.customers[i];
      let hotel = (new Hotel()).createFromApi(params);
      hotel.name = this.setDemoName(hotel, (i+1));
      hotels.push(hotel);
    }
    this.demoInterChainHotels = hotels;
    return hotels;
  }

  setDemoName(hotel, index){
    return this.sessionService.setDemoName(hotel,index);
  }

  isLiveDemo(){
    return this.sessionService.isLiveDemo();
  }

  setDemo(state){
    this.demoSetted.next(state);
  }

  isDemoSetted(): Observable<any> {
    return this.demoSetted.asObservable();
  }

  setCurrentDate(startDate, endDate, type='months', option='last_30_days'){
    this.startDate = startDate;
    this.endDate = endDate;
    this.dateSegmentedBy = type;
    this.dateReady.next({'startDate': this.startDate, 'endDate': endDate,'type' : this.dateSegmentedBy,'option':option})
  }
  getNameFilterDate(){
    return 'shared.filters.date.filters.'+this.filterDate
  }

  getCurrentDates(){
    const date = new FilterDate(this.startDate, this.endDate);
    return date;
  }

  isCurrentDateChange(): Observable<any> {
    return this.dateReady.asObservable();
  }

  // JUST O MAKE EASIER TRANSITION
  isCurrentDateRawChange(): Observable<any> {
    return this.dateReadyRaw.asObservable();
  }

  emitCurrentDateRaw(startDate, endDate, type='months', option="last_30_days"){
    this.dateReadyRaw.next({startDate, endDate, type, option})
  }

  replaceWhiteSpaces(word:string,replace='_'){
    return word.replace(/ /g,replace);
  }
  cleanString(word:string){
    return word.replace(/[^a-zA-Z ]/g, "");
  }
  stringToKey(word:string){
    return this.replaceWhiteSpaces(this.cleanString(word)).toLowerCase();
  }

  getAverageArray(array:number[], includeZero=false){
    let element_with_zero = 0;
    if(includeZero){
      array.forEach(element => {
        if (element === 0){
          element_with_zero++;
        }
      });
    }
    let sum = array.reduce(function(a, b) { return a + b; });
    let avg = sum / (array.length-element_with_zero);
    return avg;
  }

  sumArray(array){
    return array.reduce(function(a, b) { return a + b; }, 0);
  }

  multiplyAndSumArray(array1:number[],array2:number[]){
    let sum = 0;
    for (let i = 0; i < array1.length; i++) {
      sum += array1[i]*array2[i];
    }
    return sum;
  }


  capitalize(text:string){
    return text.replace(/\b\w/g, l => l.toUpperCase());
  }

  capitalizeFirst(text:string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  hideString(value:string, char="*"){
    value = value.replace('.','');
    return value.length > 1 ? value.replace(/\d(?=\d{1})/g, char) : char;
  }

  protectEmail(value){
    return this.isLiveDemo() ? this.hideEmail(value) : value;
  }

  protectQuestion(value){
    return this.isLiveDemo() ? this.hideQuestion(value) : value;
  }

  hideEmail(email){
    return email.replace(/^(.)(.*)(.@.*)$/,
     (_, a, b, c) => a + b.replace(/./g, '*') + c);
  }

  hideQuestion(label){
    let cut = label.length/2;
    return label.substring(0,cut)+'...';
  }

  validateShowData(value){
    return value===undefined ? '' : value;
  }

  isProduction(){
    return `${environment.envName}` === 'production';
  }

  isDevelopment(){
    return `${environment.envName}`==='development';
  }

  isLocal(){
    return `${environment.envName}`==='local';
  }

  isQA(){
    return `${environment.envName}`==='qa';
  }

  getOtaLogo(name,type="square"){
    return "https://statics.myhotel.io/logos/otas/"+type+"/"+name+".png";
  }

  chunkArray(array,block){
    let chunks: any[] = [];
    let i = 0;
    let n = array.length;
    while (i < n){
      chunks.push(array.slice(i, i += block));
    }
    return chunks;
  }

  parseToHashtag(word:string){
    return '#'+(word.toLowerCase()).replace(' ','-');
  }

  redirecrPriority(hotel){
    return this.productsService.redirecrPriority(hotel);
  }

  getProductsMH(){
    return this.productsService.mh_products;
  }

  checkWarings(from){
    this.notificationsService.checkModalAlert(from);
  }

  getProductById(id){
    id = parseInt(id);
    return this.fidelityProducts[id];
  }

  getProductByName(name): any {
    const keys = Object.keys(this.fidelityProducts);
    let id: any = null;
    keys.forEach(key=>{
      const product = this.fidelityProducts[key];
      if(name.includes(product)){
        id = key;
      }
    })
    return id;
  }

  getProductForAlerts(){
    let array = Object.keys(this.fidelityProducts);
    return array;
  }

  toMd5(value:string){
    return Md5.hashStr(value)
  }

  getTranslates(key){
    return this.translate.get(key);
  }

  getTranslatedValue(key: any) {
    if (!key) return '';
    return this.translate.instant(key)
  }

  listHours(interval=15){
    let block = Math.floor(60/interval);
    let arr: any = [], i, j;
    for(i=0; i<24; i++) {
      for(j=0; j<block; j++) {
        arr.push(i + ":" + (j===0 ? "00" : interval*j) );
      }
    }
    return arr;
  }

  getMoneys(base='USD'){
    if(base==='USD') return this.moneys;
    let transform  = this.moneys["currencies"].slice();
    let base_money = transform.find(x=>x.code===base).value
    let results: any = []
    for(let money of transform){
      money.value = money.value/base_money;
      results.push(money);
    }
    return this.moneys;
  }

  encryptString(string:string){
    return (new Array(string.length+1)).join('*');
  }

  arrayBetween(min,max,step=1){
    let response: any = [];

    for(let i=min; i<=max; i=i+step) {
      response.push(i);
    }
    return response;
  }

  getVaration(actual:number,previous:number, type='percentage'){
    if(actual && previous){
      const diference = actual - previous;
      const variation = previous > 0 ? ((diference) / previous * 100) : 0;
      if(type==='percentage'){
        return this.formatPercent(variation);
      }else if(type==='nps'){
        return this.formatNPS(variation);
      }else{
        return variation;
      }
    }else{
      return null
    }

  }

  decodeBase64(base64){
    return atob(base64);
  }

  getDateTrendType(startDate,endDate, type){
    let date = this.stringDateToDate(endDate);
    let start = startDate;
    if(type==='weeks'){
      start = this.formatDate(this.getLastWeeks(12,date));
    }else if(type==='days'){
      start = this.formatDate(this.getLastDays(15,date));
    }
    return start;
  }

  fillArray(length:number, first=0){
    const array = new Array(length);
    return array.fill(0).map((v,i)=>(i+first));
  }

  getIsPaid(){
    return this.is_paid;
  }

  setIsPaid(value){
    this.is_paid = value;
  }

  sendIsPaid(paid: boolean){
    this.subjectIsPaid.next(paid);
  }

  isPaid(): Observable<any> {
    return this.subjectIsPaid.asObservable();
  }

  getAfterSection(): string {
    return this._afterCurrentSection;
  }

  setAfterSection(value: string) {
    this._afterCurrentSection = value;
  }

  copyObject(obj){
    return JSON.parse(JSON.stringify(obj));
  }

  convertFormatRankingTA(rankingTA, propertiesTA) {
    return {
      actual: rankingTA.actual,
      previous: rankingTA.previous,
      variation: rankingTA.variation,
      total: propertiesTA.total_properties.actual
    }
  }

  getCaseErrorTranslate(code: number) {
    switch(code) {
      case 4000: return 'cases.errors.case_not_found'
      case 4001: return 'cases.errors.no_permission'
      case 4002: return 'cases.errors.status_not_found'
      case 4003: return 'cases.errors.user_subscribed'
      case 4004: return 'cases.errors.error_subscribe'
      case 4005: return 'cases.errors.action_not_found'
      default: return 'waiting.messages.wait_fail'
    }
  }

  isFilterClicked(): Observable<boolean> {
    return this.subjectFilterClicked.asObservable();
  }

  filterClicked() {
    this.subjectFilterClicked.next(true);
  }

  get currentHotel(){
    return this._currentHotel;
  }

  set currentHotel(hotel){
    this._currentHotel = hotel;
  }

  get idCustomer(): string {
    return this._idCustomer;
  }

  get nameCustomer(): string {
    return this._nameCustomer;
  }

  set idCustomer(value: string) {
    this._idCustomer = value;
  }

  set nameCustomer(value: string) {
    this._nameCustomer = value;
  }

  get startDate(): string {
    return this._startDate;
  }

  set startDate(value: string) {
    this._startDate = value;
  }

  get endDate(): string {
    return this._endDate;
  }

  set endDate(value: string) {
    this._endDate = value;
  }

  get prevStartDate(): string {
    return this._prevStartDate;
  }

  set prevStartDate(value: string) {
    this._prevStartDate = value;
  }

  get prevEndDate(): string {
    return this._prevEndDate;
  }

  set prevEndDate(value: string) {
    this._prevEndDate = value;
  }

  get currentLanguage(): string {
    return this._currentLanguage;
  }

  set currentLanguage(value: string) {
    this._currentLanguage = value;
  }

  get currentSection(): string {
    return this._currentSection;
  }

  set currentSection(value: string) {
    this._currentSection = value;
  }

  get currentCurrency(): Money {
    return this._currentCurrency;
  }

  set currentCurrency(value: Money) {
    this._currentCurrency = value;
  }

  get screenHeight(): number {
    return this._screenHeight;
  }

  set screenHeight(value: number) {
    this._screenHeight = value;
  }

  get screenWidth(): number {
    return this._screenWidth;
  }

  set screenWidth(value: number) {
    this._screenWidth = value;
  }

  get interChainHotels(): Hotel[] {
    return this._interChainHotels;
  }

  set interChainHotels(value: Hotel[]) {
    this._interChainHotels = value;
  }

  get demoInterChainHotels(): Hotel[] {
    return this._demoInterChainHotels;
  }

  set demoInterChainHotels(value: Hotel[]) {
    this._demoInterChainHotels = value;
  }

  get customerType() {
    if(this.currentHotel) {
      return this.currentHotel.property_type
        && (
          this.currentHotel.property_type.description ||
          this._propertyTypes[this.currentHotel.property_type.id]
        );
    }
  }

  get customerIsRetail() { return this.customerType === 'Retail'; }
  get customerIsClinic() { return this.customerType === 'Clinic'; }
  get customerIsRestaurant() { return this.customerType === 'Restaurant' || this.customerType === 'Casino'; }
  get customerIsAirline() { return this.customerType === 'Airline'; }
  get customerIsCasino() { return this.customerType === 'Casino'; }
  get customeriIsOnlyRestaurant() { return this.customerType === 'Restaurant'; }

}

export class DataPeriod{
  label:string;
  value: number;
  date: Date;
  format:string;
  constructor(label, value, date:Date,format){
    this.label = label;
    this.value = value;
    this.date = date;
    this.format = format
  }
}
