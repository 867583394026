<section class="tw-text-center tw-pt-5">
    <mh-core-lib-loader-wrapper
        [type]="'spinner'"
        [loading]="loading"
        className="tw-self-center"
        spinnerClassName="tw-text-[50px]">
    </mh-core-lib-loader-wrapper>
    <ng-container *ngIf="!loading">
        <article>
            <p class="tw-font-medium">{{ 'cases.confirmation_modal.share_title' | translate }}</p>
        </article>
    
        <article class="tw-flex tw-justify-center tw-items-center tw-gap-5 tw-mt-6 tw-mb-10">
            <img class="tw-w-14 tw-h-14 tw-cursor-pointer" (click)="handleShare('ws')" src="https://mh-statics-dev-new.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/img/whatsapp.png" alt="whatsapp logo">
            <img class="tw-w-12 tw-h-12 tw-cursor-pointer" (click)="handleShare('email')" src="https://mh-statics-dev-new.s3.sa-east-1.amazonaws.com/uploads/fidelity-suite/img/mail-icon.png" alt="mail logo">
        </article>
    
        <article class="tw-mb-4">
            <p class="tw-font-medium tw-text-sm">{{ 'cases.confirmation_modal.share_subtitle' | translate }}</p>
        </article>
    
        <article>
            <div class="tw-w-full tw-bg-gray-100 tw-py-2 tw-px-4 tw-rounded-md tw-text-left tw-flex tw-justify-between tw-items-center tw-gap-3">
                <span class="tw-text-sm tw-truncate" [mhTooltip]="shareLinks?.url_copy" position="above">{{ shareLinks?.url_copy }}</span>
                <span class="tw-cursor-pointer"[mhTooltip]="'commons.copy' | translate" position="above" (click)="handleCopyUrl(shareLinks?.url_copy)"><i class="far fa-copy tw-text-lg"></i></span>
            </div>
        </article>
    </ng-container>
</section>
