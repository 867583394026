import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { AdminCustomerService } from 'app/admin/customers/admin-customer.service';
import { DateService } from 'app/shared/services/date.service';
import { UtilService } from 'app/shared/util.service';
import { CustomersState } from 'app/states/customers/customers.state';
import { Observable, Subscription } from 'rxjs';
import { Ota } from './otas/models/Ota.model';

@Component({
  selector: 'mh-online',
  templateUrl: './online.component.html',
  styleUrls: ['./online.component.scss'],
  providers: [AdminCustomerService],
  encapsulation: ViewEncapsulation.None,
})
export class OnlineComponent implements OnInit, OnDestroy {
  chain;
  @Select(CustomersState) customersReady$: Observable<any[]>;
  customersStateSubscribe: Subscription;

  readonly ROUTES = ['resumen', 'otas', 'resenas'];
  readonly SECTIONS = ['summary', 'otas', 'reviews'];
  readonly REPORTS = ['summaryOnline', 'rankingOtasOnline', 'reviewsOnline'];
  readonly GOOGLE_OTA_ID = 30;
  readonly FACEBOOK_OTA_ID = 31;
  
  IRO_VIDEOS_LINK = {
    es: 'https://youtu.be/LzFoQcxSGy8',
    pt: 'https://youtu.be/U8_E9_ISqFk',
    en: 'https://www.youtube.com/watch?v=UX35eMxLsxw',
  };

  activeRoute = 'summary';
  activeReport = 'summaryOnline';
  integrationButtons: Ota[] = [];
  surveyUrlInfo = {
    name: '',
    lastname: '',
    email: '',
    room: '',
    phone: '',
    reservation_id: '',
    country: '',
    guest_code: '',
    lang: '',
  };

  constructor(
    private dateService: DateService,
    private store: Store,
    private router: Router,
    private utilService: UtilService,
  ) {}

  ngOnInit(): void {
    this.chain = this.store.selectSnapshot((state) => state.customers.relativeCurrentChain);
    this.customersStateSubscribe = this.customersReady$.subscribe((state: any) => {
      this.chain = state.relativeCurrentChain;
    });
    this.setActivateSection();
    this.setSurveyUrlInfo();
  }

  setSurveyUrlInfo() {
    this.surveyUrlInfo = {
      name: this.utilService.getCurrentUser().name,
      lastname: this.utilService.getCurrentUser().lastName,
      email: this.utilService.getCurrentUser().email,
      room: this.utilService.getCurrentHotel().chain_brand,
      phone: this.utilService.getCurrentUser().profile.phone,
      reservation_id: this.utilService.getCurrentHotel().name,
      country: this.utilService.getCurrentHotel().country_code,
      guest_code: this.utilService.getCurrentUser().companyPosition,
      lang: this.utilService.currentLanguage,
    };
  }

  handleDatepickerUpdated($event) {
    this.dateService.setCurrentFilter($event.currentFilter);
    this.dateService.setCurrentDates($event.currentDates);
  }

  setActivateSection() {
    this.activeRoute = this.SECTIONS[this.ROUTES.findIndex((route) => route === this.currentPath)];
    this.activeReport = this.REPORTS.filter((report) => report.toLowerCase().includes(this.activeRoute)).toString();
  }

  headerAreaClicked() {
    this.utilService.filterClicked();
  }

  get currentDates() {
    return this.dateService.currentDates;
  }

  get currentFilterDate() {
    return this.dateService.currentFilter;
  }

  get isMobile() {
    return this.utilService.isMobile();
  }

  get video() {
    return this.IRO_VIDEOS_LINK[this.utilService.currentLanguage];
  }

  get reportSlug() {
    const slugs = {
      resumen: 'online_summary_report',
      otas: 'online_otas_report',
      resenas: 'online_reviews_report',
    };
    return slugs[this.currentPath];
  }

  get currentPath() {
    return this.router.url.split('/')[this.router.url.split('/').length - 1].split('?')[0];
  }

  get surveyUrl() {
    const url = new URL('https://surveys.myhotel.cl/survey-public/surveys-open/Ro8MPtIUThGED2Jep7eXGA');
    const params = new URLSearchParams(this.surveyUrlInfo);
    return `${url}?${params}`;
  }

  get currentLanguage() { return this.utilService.currentLanguage; }
  get pdfUrl() {
    return `https://statics.myhotel.io/documents/Guias/coverage-indicator-${this.currentLanguage}.pdf `
  }

  ngOnDestroy(): void {
    this.customersStateSubscribe.unsubscribe();
  }
}
