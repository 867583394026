import { Hotel } from "app/shared/model";

enum LogType {
  PATCH = "edit",
  POST = "create",
  DELETE = "delete",
  PUT = "edit",
}

interface Log {
  category: string,
  timestamp: Date;
  user: User | any;
  userTarget: User | any;
  customer: Hotel | any;
  requestMethodType: string;
  resourceName: string;
  resourceId: any;
  subResourceId: any;
  type: LogType;
  product: string;
  changes: Change[];
}

interface User {
  id: number;
  firstName: string;
  lastName: string;
  title: string;
  fullName: string;
  profile_photo: string;
}

interface Change {
  field: string;
  prevValue: string|number;
  value: string|number;
  name?: string;
  previous?: string | number;
  actual?: string | number;
  review_id?: number;
  answered_survey_id?: string | number;
  user_id?: number;
  action_date?: string;
  customerId?: number;
  surveyId?: number;
}

export {
  Log,
  LogType,
  User,
  Change,
}
